<template>
  <div class='equipment'>
    <div class="tab">
      <h3>设备管理</h3>
      <div class="btn">
        <el-button class="btn_info" style="margin-right:18px" @click="addDevice()">设备添加</el-button>
        <el-badge :value="offlineList.length" class="item">
          <el-button class="btn_info" @click="offline()">离线设备</el-button>
        </el-badge>
      </div>
    </div>
    <base-table ref="myTable" :filterList="filterList" :columns="columns" :tableList="tableData" @queryForm="queryForm"></base-table>
    <base-pagination class="basepagination" v-show="total>10" :total="total" :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize" :layout="layout" @pagination="getList"></base-pagination>
    <!-- 联系人弹框 -->
    <el-dialog title="联系人信息" :visible.sync="contactVisible" width="20%" :lock-scroll="false" top="40vh">
      <p class="item">姓名：<span>{{adminName}}</span></p>
      <p class="item">电话：<span>{{phone}}</span></p>
    </el-dialog>
    <!-- 添加设备弹框 -->
    <add-device ref="device" @successAdd="successAdd" />
  </div>
</template>

<script>
import AddDevice from './components/add.vue'
import { getDeviceList, deleteDevice } from '@/api'
import { mapGetters } from 'vuex'
export default {
  components: { AddDevice },
  data () {
    return {
      layout: 'prev, pager, next',
      // 所有数据
      totalList: [],
      // 离线数据
      offlineList: [],
      total: 0,
      tableData: [],
      value: '',
      queryParams: {
        pageNum: 1,
        pageSize: 10,
      },
      // 联系人弹框
      contactVisible: false,
      adminName: '',
      phone: '',
      columns: [
        { attrs: { prop: 'id', label: '设备ID', align: 'center', width: '120' } },
        { attrs: { prop: 'siteName', label: '单位名称', align: 'center', minWidth: '200' } },
        { attrs: { prop: 'roomName', label: '设备位置', align: 'center' } },
        {
          attrs: { label: '联系人', align: 'center' },
          btns: row => [
            { name: '查看', attrs: { type: 'text' }, listeners: { click: () => this.viewContact(row) } }
          ]
        },
        {
          attrs: { label: '联网状态', align: 'center', prop: 'onlinestatus' },
          statusList: [
            { value: true, label: '在线', style: { color: '#08C04D' } },
            { value: false, label: '离线', style: { color: '#E72E2E' } },
            { value: undefined, label: '离线', style: { color: '#E72E2E' } }
          ]
        },
        {
          attrs: { label: '实时画面', width: '80', align: 'center' },
          btns: row => [
            { name: '暂无', attrs: { type: 'text' }, condition: row => !row.onlinestatus, },
            { name: '查看', attrs: { type: 'text' }, condition: row => row.onlinestatus, listeners: { click: () => this.viewImage(row) } },
          ]
        },
        {
          attrs: { label: '参数设置', width: '80', align: 'center' },
          btns: row => [
            { name: '暂无', attrs: { type: 'text' }, condition: row => !row.onlinestatus, },
            { name: '查看', attrs: { type: 'text' }, condition: row => row.onlinestatus, listeners: { click: () => this.viewParameter(row.id) } }
          ]
        },
        {
          attrs: { label: '添加时间', sortable: true, prop: 'gmtcreate', align: 'center', minWidth: '100' }
        },
        {
          attrs: { label: '操作', align: 'center', width: '50' },
          btns: row => [
            { iconclass: 'el-icon-delete', msg: '您确认要删除该摄像头吗？', listeners: { click: () => this.ensureDelete(row) }, popover: { attrs: { width: 80 } } }
          ]
        }
      ]
    };
  },
  created () {
    this.init()
  },
  computed: {
    ...mapGetters(['locationList']),
    filterList () {
      const arr = [
        {
          name: '设备位置：',
          type: 'cascader',
          options: this.locationList,
          props: { expandTrigger: 'hover', value: 'id', label: 'name' },
          attrs: {
            style: { width: '350px' },
            size: 'small'
          },
          fieldNames: ['siteid', 'roomid']
        }
      ]
      return arr
    }
  },
  mounted () {

  },
  methods: {
    init () {
      this.offlineList = []
      getDeviceList(this.$tools.concatParams(this.queryParams)).then(res => {
        // console.log(res)
        this.totalList = res.data.data
        this.total = res.data.data.length
        this.totalList.map(el => {
          if (!el.onlinestatus) {
            this.offlineList.push(el)
          }
        })
        this.getList()
      })
    },
    getList () {
      // console.log(val)
      this.tableData = []
      var start = (this.queryParams.pageNum - 1) * this.queryParams.pageSize
      var end = ''
      if (this.queryParams.pageNum * this.queryParams.pageSize > this.total) {
        end = this.total
      }
      else {
        end = this.queryParams.pageSize * this.queryParams.pageNum
      }

      for (let i = start; i < end; i++) {
        this.tableData.push(this.totalList[i])
      }
    },
    queryForm (val) {
      this.queryParams = {
        ...val,
        pageNum: 1,
        pageSize: 10,
      }
      this.init()
    },
    ensureDelete (val) {
      // console.log(val)
      deleteDevice(val.id).then(() => {
        this.$message.success('摄像头删除成功')
        this.init()
      })
    },
    offline () {
      this.$router.push({
        path: '/equipment/offline',
        query: {
          offlineList: this.$Base64.encode(JSON.stringify(this.offlineList))
        }
      })
    },
    // 查看联系人
    viewContact (val) {
      this.adminName = val.adminName
      this.phone = val.phone
      this.contactVisible = true
    },
    // 查看实时画面
    viewImage (val) {
      const info = {
        siteName: val.siteName,
        roomName: val.roomName,
        indexCode: val.indexCode,
        iscId: val.iscId
      }
      this.$router.push({
        path: '/equipment/realtime',
        query: {
          info: this.$Base64.encode(JSON.stringify(info))
        }
      })
    },
    // 查看参数设置
    viewParameter (id) {
      this.$router.push({
        path: '/equipment/parameter',
        query: {
          id
        }
      })
    },
    // 设备添加
    addDevice () {
      this.$refs.device.init()
    },
    // 设备添加成功
    successAdd () {
      this.init()
    }
  }
};
</script>

<style scoped lang="scss">
.equipment {
  margin: 30px 0 30px 30px;
  padding: 20px 20px 10px;
  height: 78vh;
  background: url("../../assets/body.png") no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  .tab {
    position: relative;
    h3 {
      color: #30c6fe;
      font-size: 20px;
      font-weight: bold;
    }
    .btn {
      position: absolute;
      right: 0;
      bottom: -100%;
    }
  }
  ::v-deep .filter-box {
    margin: 0;
  }
  ::v-deep .filter-wrapper {
    background: none;
    padding: 0;
    .el-form-item__label {
      color: #fff;
    }
  }
  ::v-deep .el-table {
    background: none;
  }
  ::v-deep .table-wrapper {
    background: none;
    height: 62vh;
    padding: 0;
    .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
      background: #174e92;
    }
  }
  .basepagination {
    text-align: right;
  }
}
</style>
