<template>
  <el-dialog title="添加设备" width="22%" :visible.sync="dialogFormVisible" append-to-body :lock-scroll="false" class="device_dialog" :close-on-click-modal="false">
    <el-form :model="deviceForm" :label-position="'top'" :rules="rules" ref="deviceForm" class="demo-ruleForm">

      <el-form-item prop="iscplatformid" label="ISC平台列表：">
        <el-select v-model="deviceForm.iscplatformid" placeholder="请选择ISC平台" size="small">
          <el-option v-for="item in iscList" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="cameraname" label="摄像头：">
        <el-input placeholder="请选择摄像头" v-model="deviceForm.cameraname" size="small" :disabled="true">
          <template slot="append">
            <el-button class="btn_info" @click="viewCamera()">摄像头列表</el-button>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item prop="siteid" label="所属单位：">
        <el-select v-model="deviceForm.siteid" placeholder="请选择所属单位" size="small" filterable @change="choiceSite">
          <el-option v-for="item in locationList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="roomId" label="监控位置：">
        <el-select v-model="deviceForm.roomId" placeholder="请选择监控位置" size="small">
          <el-option v-for="item in placeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="algorithmIds" label="场景算法：">
        <el-select v-model="deviceForm.algorithmIds" multiple placeholder="请选择场景算法">
          <el-option v-for="item in algorithmList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">取 消</el-button>
      <el-button type="primary" @click="submitDevice" :loading="addLoading">确 定</el-button>
    </div>
    <!-- 设备列表 -->
    <el-drawer :lock-scroll="false" title="摄像头列表" :size="'70%'" :visible.sync="drawer" :direction="direction" append-to-body>
      <vue-scroll :ops="ops" style="width:30%">
        <el-tree :data="treeList" :props="defaultProps" node-key="indexCode" :default-expanded-keys="expandedKeys" @node-click="handleNodeClick"></el-tree>
      </vue-scroll>
      <el-divider direction="vertical" style="height:100%"></el-divider>
      <div class="box">
        <div>
          <div class="tablebox">
            <base-table ref="myTable" :columns="columns" :tableList="tableData" @radioChange="currentRow"></base-table>
          </div>
          <base-pagination class="basepagination" v-show="total>10" :total="total" :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize" :layout="layout" @pagination="getList"></base-pagination>
        </div>
        <el-button class="btn_info" @click="confirm">确定</el-button>
      </div>
    </el-drawer>
  </el-dialog>
</template>

<script>
import { getIscList, getHotel, getUnitDevice, addDevice } from '@/api'
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      dialogFormVisible: false,
      iscList: [],
      deviceForm: {
        cameraname: '',
        iscplatformid: '',
        siteid: '',
        roomId: '',
        algorithmIds: []
      },
      rules: {
        cameraname: [
          { required: true, message: '请选择摄像头', trigger: 'blur' }
        ],
        siteid: [
          { required: true, message: '请选择所属单位', trigger: 'change' }
        ],
        iscplatformid: [
          { required: true, message: '请选择ISC平台', trigger: 'change' }
        ],
        roomId: [
          { required: true, message: '请选择监控位置', trigger: 'change' }
        ],
        algorithmIds: [
          { required: true, message: '请选择场景算法', trigger: 'change' }
        ]
      },
      drawer: false,
      direction: 'rtl',
      defaultProps: {
        label: 'name',
        children: 'children'
      },
      // 侧边树状数据
      treeList: [],
      // 默认展开节点
      expandedKeys: [],
      // 表格所有数据
      totalList: [],
      // 表格选中数据
      choiceValue: {},
      // 监控位置列表
      placeList: [],
      // 滚动条参数
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {
          keepShow: true,
        },
        bar: {
          hoverStyle: true,
          onlyShowBarOnScroll: false, //是否只有滚动的时候才显示滚动条
          background: '#999999', //滚动条颜色
          opacity: 0.6, //滚动条透明度
          'overflow-x': 'hidden',
          minSize: 0.1
        },
        videoData: [],
      },
      tableData: [],
      radio: null,
      total: 10,
      queryParams: {
        pageNum: 1,
        pageSize: 10,
      },
      layout: 'prev, pager, next',
      columns: [
        {
          attrs: { minWidth: '55', align: 'center' },
          radio: true
        },
        { attrs: { prop: 'cameraName', label: '摄像头名称', align: 'center', minWidth: '100' } },
        { attrs: { prop: 'cameraTypeName', label: '摄像头类型', align: 'center' } },
        {
          attrs: { label: '创建时间', align: 'center', prop: 'createTime' },
          time: 'YYYY-MM-DD hh:mm:ss'
        },
      ],
      addLoading: false
    };
  },
  created () {

  },
  mounted () {

  },
  computed: {
    ...mapGetters(['locationList', 'algorithmList'])
  },
  methods: {
    init () {
      getIscList().then(res => {
        const keys = Object.keys(res.data.data)
        const values = Object.values(res.data.data)
        keys.map((el, index) => {
          this.iscList.push({
            label: values[index],
            value: el
          })
        })
        // console.log(this.iscList)
      })
      this.dialogFormVisible = true
    },
    viewCamera () {
      // console.log(Boolean(this.deviceForm.iscplatformid))
      if (this.deviceForm.iscplatformid) {
        getHotel(this.deviceForm.iscplatformid).then(res => {
          this.treeList = res.data.data
          res.data.data.map(el => {
            if (el.children && el.children.length > 0) {
              el.children.map(item => {
                this.expandedKeys.push(item.indexCode)
              })
            }
          })
          this.drawer = true
        })
      } else {
        this.$message.error('请选择ISC平台')
      }
    },
    // 查询酒店下的设备信息
    handleNodeClick (data) {
      if (data.children.length === 0) {
        // this.$refs.board.startPlayBack(data.indexCode)
        const params = {
          iscid: this.deviceForm.iscplatformid,
          regionid: data.indexCode
        }
        getUnitDevice(this.$tools.concatParams(params)).then(res => {
          // console.log(res)
          this.totalList = res.data.data.list
          this.total = res.data.data.list.length
          this.getList({ limit: 10, page: 1 })
        })
      }
    },
    // 选择所添加的设备
    currentRow (row) {
      this.choiceValue = row
      this.deviceForm.cameraname = row.cameraName
    },
    getList () {
      this.tableData = []
      var start = (this.queryParams.pageNum - 1) * this.queryParams.pageSize
      var end = ''
      if (this.queryParams.pageNum * this.queryParams.pageSize > this.total) {
        end = this.total
      }
      else {
        end = this.queryParams.pageSize * this.queryParams.pageNum
      }

      for (let i = start; i < end; i++) {
        this.tableData.push(this.totalList[i])
      }
    },
    confirm () {
      this.drawer = false
    },
    // 选择单位
    choiceSite (val) {
      this.deviceForm.roomId = ''
      this.placeList = []
      this.placeList = this.locationList.find(el => el.id === val).children
      // console.log(this.placeList)
    },
    submitDevice () {
      this.$refs.deviceForm.validate((valid) => {
        if (valid) {
          this.addLoading = true
          // console.log(this.deviceForm)
          const params = {
            roomId: this.deviceForm.roomId,
            iscid: Number(this.deviceForm.iscplatformid),
            indexCode: this.choiceValue.cameraIndexCode,
            name: this.deviceForm.cameraname,
            siteId: this.deviceForm.siteid,
            algorithmIds: this.deviceForm.algorithmIds.toString(),
          }
          addDevice(this.$tools.concatParams(params)).then(() => {
            // console.log(res)
            this.$message.success('设备添加成功')
            this.dialogFormVisible = false
            this.addLoading = false
            this.$emit('successAdd', true)
          }).catch(() => {
            this.addLoading = false;
          })
          // console.log(params)
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.device_dialog {
  ::v-deep .el-dialog__body {
    padding: 20px;
    .el-form {
      .el-form-item {
        // margin-bottom: 20px;
        .el-form-item__label {
          padding-bottom: 5px;
          line-height: 1;
        }
        .el-form-item__label:before {
          content: "";
        }
        .el-form-item__content {
          line-height: 1;
          .el-select {
            width: 100%;
          }
          .el-input-group__append {
            .el-button {
              background: #1a6899;
              border: 1px solid #30c6fe;
              color: #fff;
              border-radius: 0;
            }
          }
        }
      }
    }
  }
}
::v-deep .table-wrapper {
  height: 568px;
}
::v-deep .el-drawer__body {
  display: flex;
  .box {
    width: 66%;
    margin-left: 10px;
    position: relative;
    // .tablebox {
    //   height: 50vh;
    // }
    // display: flex;
    // flex-direction: column;
    // justify-content: space-between;
    .el-button {
      // margin-bottom: 10px;
      width: 10%;
      position: absolute;
      bottom: 10px;
      right: 0;
    }
    .basepagination {
      margin-top: 10px;
      text-align: right;
    }
  }
  .el-table th.el-table__cell {
    background: rgba(165, 165, 165, 0.3);
    color: #333;
    padding: 12px 0;
  }

  .el-table tr {
    background: #fff;
    color: #666;
    height: 5.5vh;
  }

  .el-table tr:hover {
    background: #fff;
    color: #666;
  }

  .el-table .el-table__cell {
    padding: 0;
  }

  .el-table--striped
    .el-table__body
    tr.el-table__row--striped
    td.el-table__cell {
    background: #fff;
  }

  .el-table--enable-row-hover
    .el-table__body
    tr:nth-child(2n-1):hover
    > td.el-table__cell {
    background: #fff;
  }
  .el-radio__input.is-checked + .el-radio__label {
    color: #fff;
  }
  // justify-content: space-around;
}
</style>
